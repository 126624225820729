<template>
  <div class="wrapper">
    <div class="header_wrapper">
      <h1 class="title_wrapper">
        {{ $t("gamesets.GAMESET_DATA") }}
      </h1>
      <v-btn
          color="primary"
          class="btn"
          @click="handleRedirect('gamesetDivision')"
      >
        {{ $t("gamesets.TO_DIVISION") }}
      </v-btn>
    </div>


    <div class="card_wrapper">
      <GamesetCard :card-data="cardData">
        <template #buttons>
          <div class="row">
            <div class="btn_container">
              <v-btn
                  v-for="item in gameset.template.games"
                  :key="item.key"
                  class="game_btn"
                  depressed
                  @click="handleClickGame(item.key)"
              >
                {{ item.key }}
              </v-btn>
            </div>
            <div v-if="faceStudyIndex !== -1">
              <Excel
                  :is-disabled="!gameset.is_completed"
                  :title="$t('lookup.EXPORT_FACE_STUDY')"
                  @click="fetchExcelFile('faceStudy')"
              />
            </div>
          </div>
        </template>
      </GamesetCard>
    </div>

    <div class="search_wrapper">
      <Search :label="$t('lookupPlaceholder.DEFAULT')"/>
    </div>
    <v-app>
      <FilterList/>
      <div class="checkbox_wrapper">
        <Checkbox
            :value="hideOutlier"
            :label="$t('gamesets.HIDE_OUTLIER')"
            @input="handleUpdateCheckbox"
        />
      </div>
      <v-data-table
          v-if="gameHeaders && dataList.length"
          class="elevation-1"
          dense

          :headers="[...headers, ...gameHeaders]"
          :items="dataList"
          :options.sync="sortOptions"
          :hide-default-footer="true"
          :server-items-length="dataList.length"
      >
        <template
            v-for="header in gameHeaders"
            #[`item.${header.value}`]="{ item }"
        >
          <div
              :key="header.text"
              :class="isSummaryOutlier(item.summary[header.index]) && 'bg_color'"
          >
            {{ item.summary[header.index][header.text] }}
          </div>
        </template>




      </v-data-table>
      <Pagination/>
      <div class="button-container">
        <Excel @click="fetchExcelFile"/>
      </div>


    </v-app>
  </div>


</template>

<script>
import GamesetCard from "@/components/gameset/GamesetCard"
import Checkbox from "@/components/common/formControls/Checkbox"
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import sort from "@/mixins/sort"
import {
  getExcelDataByGamesetId,
  getFaceStudyByGamesetId, getPdfFile, setTeamScore, getTeamScore,
} from "@/api/exportExcel"
import {getCurrentDate,} from "@/utils/date"
import {MENU, CATEGORY,} from "@/constants/mapVarNames"
import * as accountsApi from "@/api/accounts";

export default {
  components: {
    GamesetCard,
    Checkbox,
  },
  mixins: [table, excel, sort, param],
  data() {
    const basePropsHeader = [
      {
        class: "table_header_xs",
        text: this.$t("headers.ID"),
        value: "id",
        sortable: false,
      },

      {
        class: "table_header_xs",
        text: "username",
        value: "user.username",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "name",
        value: "user.name",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "age",
        value: "user.age",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "gender",
        value: "user.gender",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "experience",
        value: "user.experience",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "position",
        value: "user.position",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "funnel",
        value: "user.funnel",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "education",
        value: "user.education",
        sortable: false,
      }
    ]

    return {
      hideOutlier: false,
      filterItems: {
        division: null,
      },
      headers: [...basePropsHeader],
      gameHeaders: null,
      faceStudyIndex: -1,
      sortOptions: {},
      score1: 0,
      score2: 0,
      score3: 0,
      score4: 0,
      score5: 0,
      score6: 0,
      errorMessage: '',
    }
  },
  computed: {


    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
    games() {
      return this.gameset.template.games
    },
    cardData() {
      const gameset = `${this.gameset.id} - ${this.gameset.name}`
      let company_name = ""
      let username = ""

      if (this.gameset.user) {
        company_name = this.gameset.user.company_name
        username = this.gameset.user.username
      }

      return {
        company_name,
        username,
        gameset: gameset ? gameset : "",
        template: gameset ? gameset : "",
      }
    },
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", {menu: MENU.gamesets, category: CATEGORY.data,})
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.commit("SET_SORT", null)
    this.$store.commit("SET_HIDE_OUTLIER", false)
    await this.$store.dispatch("fetchLookupData")

    if (this.dataList.length) {
      this.handleSetGameProps()
    }

    this.findFaceStudyIndex()
  },

  mounted() {
    this.initScore()
  },
  methods: {
    async initScore() {
      try {
        let response = await getTeamScore(this.gameset.id)
        this.score1 = response.data.data.default_team_score.p_score_c
        this.score2 = response.data.data.default_team_score.n_score_c
        this.score3 = response.data.data.default_team_score.c_w_score_c
        this.score4 = response.data.data.default_team_score.c_p_score_c
        this.score5 = response.data.data.default_team_score.s_score_c
        this.score6 = response.data.data.default_team_score.r_score_c
      } catch (error) {
      }

    },

    async showScores() {

      const gamesetData = {
        default_team_score: {
          p_score_c: this.score1,
          n_score_c: this.score2,
          c_w_score_c: this.score3,
          c_p_score_c: this.score4,
          s_score_c: this.score5,
          r_score_c: this.score6
        }
      }

      try {
        let response = await setTeamScore(gamesetData, this.gameset.id)
        alert('평균 점수가 설정 되었습니다.')

      } catch (error) {
      }


    },
    findFaceStudyIndex() {
      const games = this.gameset.template.games

      for (let i = 0; i < games.length; i++) {
        if (games[i].key === "face") {
          this.faceStudyIndex = i
          break
        }
      }
    },
    isSummaryOutlier(summary) {
      return summary[`${summary.key}_percentile`] === -1
    },
    handleRedirect(routeName) {
      this.$router.push({name: routeName,})
    },
    handleClickGame(gameKey) {
      this.$router.push({name: "gamesetGame", params: {game_key: gameKey,},})
    },
    handleUpdateCheckbox(isChecked) {
      this.hideOutlier = isChecked
      this.$store.commit("SET_HIDE_OUTLIER", isChecked)
      this.$store.dispatch("fetchLookupData")
    },
    handleSetGameProps() {
      const summaryList = this.dataList[0].summary
      const isGameHeader = (key) => key !== "key"

      this.gameHeaders = summaryList.reduce((headers, summary, i) => {
        const gameProps = Object.keys(summary)
            .filter(isGameHeader)
            .map((gameProp) => ({
              index: i,
              text: gameProp,
              value: `summary[${i}].${gameProp}`,
              sortable: gameProp.includes("percentile") ? true : false,
              class: gameProp.includes("percentile") ? "table_header_lg" : "table_header_xs",
            }))

        return [...headers, ...gameProps]
      }, [])
    },
    async downloadPDF(item) {

      try {
        this.downloadFile(item.result_file_path, 'test')

      } catch (error) {
      }

    },

    async generatePDF(item) {
      const gamesetData = {
        gameset_data_id: item.id
      }

      try {
        let response = await getPdfFile(gamesetData)
        if (response.data.data.path !== '') {
          this.downloadFile(response.data.data.path, 'test')
          item.result_file_path = response.data.data.path
        }

      } catch (error) {
      }

    },

    downloadFile(url, name) {
      // create element <a> for download PDF
      let link = document.createElement('a');
      link.href = url
      //link.target = '_blank'
      link.download = name

      // Simulate a click on the element <a>
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },


    async fetchExcelFile(excelFileType) {
      try {
        const params = this.$_param_createParams()
        let response

        if (excelFileType === "faceStudy") {
          response = await getFaceStudyByGamesetId({gamesetId: this.gameset.id,})
          this.$_excel_downloadFile(response, "faceStudy")
        } else {
          response = await getExcelDataByGamesetId({
            gamesetId: this.gameset.id,
            category: CATEGORY.data,
            params,
          })
          this.$_excel_downloadFile(response, `gameset_${CATEGORY.data}_${getCurrentDate()}`)
        }
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>


.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_wrapper {
  display: flex;
  align-items: center;
}

.card_wrapper {
  align-self: flex-start;
  margin-bottom: 1em;
}

.title_wrapper {
  margin: 1rem 0;
}

.search_wrapper {
  width: 720px;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
}

.btn {
  margin-left: 2rem;
}

.row {
  margin-top: 1em;
  display: flex;
  width: 100%;
}

.btn_container {
  width: 475px;
}

.game_btn {
  width: 9rem;
  margin-right: 1em;
  margin-bottom: 1em;
}

.bg_color {
  position: relative;
  left: -16px;
  background: red;
  color: white;
  font-weight: bolder;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: calc(100% + 32px);
}

.header {
  background: black;
  color: white;
  font-weight: bold;
  padding: 0.4em;
  padding-left: 1em;
  min-width: 100px;
}

.header_text {
  font-size: 0.9rem;
}

.table_header_xs {
  padding: 0 0.75em;
}

.table_header_sm {
  padding: 0.4em;
  min-width: 120px;
}

.table_header_md {
  padding: 0.4em;
  min-width: 160px;
}

.table_header_lg {
  padding: 0.4em;
  min-width: 200px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Add some space between the buttons if needed */
}
</style>
