import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=1302c168&scoped=true&"
import script from "./Data.vue?vue&type=script&lang=js&"
export * from "./Data.vue?vue&type=script&lang=js&"
import style0 from "./Data.vue?vue&type=style&index=0&id=1302c168&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1302c168",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VApp,VBtn,VDataTable})
